import { Col, Container, Form, Row, Spinner, Image } from "react-bootstrap";
import "../assets/css/components/longBox.css";
import Qrcode from "../assets/images/qr-code.svg";
import { QRCodeSVG } from "qrcode.react";
import { useState } from "react";
import Logout from "../assets/images/logout.svg";
import { number_format, setCookie } from "./helper";
import { Cookies } from "react-cookie";
function BigCard(props) {
    const cookies = new Cookies();
  const [user, setUser] = useState(false);
  const { className, fark, eski_satis, alis, yeni_satis } = props;
  const logoutFunction = () => {
    props.setConnection(null);
    setCookie("connection", null);
  };

  const rollUp = (number) => {
    const num = new Number(number.replaceAll(",", ""));
    // round this num to upper one decimal for example 6999 to 7000 
    const rounded = Math.ceil(num / 10) * 10;
    // convert to string
    const str = rounded.toString();
    // console.log("info",str,num);
    return number_format(str, 0, ",", ".");
  }
  return (
    <Container
      fluid
      className={` bg-transition long-box
      ${className}   ${(cookies.get("darkMode") == true ? " dark-long-box " : " light-long-box ")}`}
    >
  
      
            <Row>
              <Col xs={eski_satis != null ? 4 : 8} md={eski_satis != null ? 4 : 8} xl={eski_satis != null ? 4 : 8}>
                <h2 className="dark fw-7">{props.title}</h2>
              </Col>
              <Col xs={4} md={4} xl={4} className="d-flex align-items-center  flex-row justify-content-center">
                <h3 className="dark fw-7">{rollUp(yeni_satis)}₺</h3>
              </Col>
              {
                eski_satis != null ?
                <Col xs={4} md={4} xl={4} className="d-flex align-items-center flex-row justify-content-center">
                  <h3 className="dark fw-7">{rollUp(eski_satis)}₺</h3>
                </Col>  
                :
                null
              }
              
            </Row>
    </Container>
  );
}

export default BigCard;
