import { Col, Container, Form, Row, Spinner,Image } from "react-bootstrap";
import '../assets/css/components/big.css'
import Qrcode from "../assets/images/qr-code.svg"
import {QRCodeSVG} from 'qrcode.react';
import { useEffect, useState } from "react";
import Logout from '../assets/images/logout.svg'
import { setCookie } from "./helper";
import { Cookies } from "react-cookie";

function BigCard(props) {
    const cookies = new Cookies();
    const [user,setUser] = useState(false);
    const logoutFunction = () => {
        props.setConnection(null);
        setCookie("connection",null);
    }

    return (
        <Container fluid className={` bg-transition ${props.area == "qr-code" ? "qr-code-area":"big-card"} ${props.className} ${cookies.get("darkMode") == true ? " dark-big" : " light-big"}`}>
            <Row className="gv-40 h-100">
                {
                    props.area != "qr-code" ? 
                    <>
                      
                        <Col md={12}>
                            <Row>
                                <Col xs={6}>
                                    <h2 className="">{props.title}</h2>
                                </Col>
                                <Col xs={6} className="d-flex align-items-center justify-content-end flex-row">
                                    <h3 className="fw-4 fs-26 ">Eski Satış:</h3>
                                    <h3 className=" fw-7">{props.eski_satis}₺</h3>
                                </Col>
                                <Col xs={6} className="d-flex align-items-center flex-row">
                                    <h3 className="fw-4 fs-26 ">Alış Fiyatı:</h3>
                                    <h3 className="orange fw-7">{props.alis}₺</h3>
                                </Col>
                                <Col xs={6} className="d-flex align-items-center justify-content-end flex-row">
                                    <h3 className="fw-4 fs-26 ">Yeni Satış:</h3>
                                    <h3 className="orange fw-7">{props.yeni_satis}₺</h3>
                                </Col>
                            </Row>
                        </Col>
                    </> : 
                    <div>
                            {
                                !props.connection?.accessToken ? 
                                <>
                                    <Col md={12} className="d-flex align-items-center justify-content-between" >
                                        <div>
                                            <h4 className="fs-28 orange">Merhabalar,</h4>
                                            <p className="fs-28 fw-5 white">Lütfen profilinize bağlanın.</p>
                                        </div>
                                        <div style={{padding:5,backgroundColor:"white",borderRadius:10}}>
                                            <QRCodeSVG size={64} value={props.token} />
                                        </div>
                                    </Col>
                                </> : 
                                <>
                                    <Col md={12} className="d-flex align-items-center justify-content-evenly">
                                        <div>
                                            <h4 className="orange">{props.connection?.user?.company?.name}</h4>
                                            <p className="fs-18 grey">{`${props.connection?.user?.name} ${props.connection?.user?.surname}`}</p>
                                        </div>
                                        <div onClick={()=>logoutFunction()} className="logout">
                                            Çıkış yapın
                                            <Image style={{maxWidth:'100%'}} src={Logout} />
                                        </div>
                                    </Col>
                                </>
                            }
                    </div>
                }
            </Row>
        </Container>
    );
}

export default BigCard;