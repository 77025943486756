import { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import "../assets/css/style.css";
import "react-toastify/dist/ReactToastify.css";
import "reactjs-popup/dist/index.css";
import LittleCard from "./LittleCard";
import BigCard from "./BigCard";
import Cookies from "universal-cookie";
import axios from "axios";
import { io } from "socket.io-client";
import {
  getCookie,
  setCookie,
  formatNumber,
  farkCalc,
  number_format,
} from "./helper";
import LongBox from "./LongBox";

const Grid = (props) => {
  const {
    socket,
    haremSoket,
    token,
    settings,
    golds,
    hasAlis,
    hasSatis,
    connection,
    serializedSettings,
    defaultSettings,
    setSerializedSettings,
    setSocket,
    setHaremSoket,
    setTime,
    setConnection,
    setSettings,
    setToken,
    setGolds,
    setDefaultSettings,
    goldCalculateFormule,
  } = props.useIndex();
  const cookies = new Cookies();

  return (
    <Container fluid>
      {/* <Col lg={4} className="d-flex align-items-center">
          <LittleCard bg_color="transparent" title="Ziynet Fiyatları" />
      </Col> */}

      <Row className="gv-40">
        <Col md={8}>
          <Row>
            <Col xs={4}></Col>
            <Col xs={2} className="d-flex flex-row justify-content-center">
              <h3 className="orange fw-7 fs-20">Alış</h3>
            </Col>
            <Col
              xs={2}
              className="d-flex align-items-center flex-row justify-content-center"
            >
              <h3 className="dark fw-7 fs-20" style={{
                color: cookies.get("darkMode") ? "#f5f5f5" : "#0c1528"
              }}>Eski Satış</h3>
            </Col>

            <Col
              xs={2}
              className="d-flex align-items-center  flex-row justify-content-center"
            >
              <h3 className="dark fw-7 fs-20" style={{
                color: cookies.get("darkMode") ? "#f5f5f5" : "#0c1528"
              }}>Yeni Satış</h3>
            </Col>
            <Col md={2}></Col>
          </Row>
          <LongBox
            area="not-qr-code"
            className={golds?.TEK_YENI?.dir}
            fark={farkCalc(golds?.TEK_YENI?.satis / golds?.TEK_YENI?.kapanis)}
            status="success"
            title="0.5 Gram (22a)"
            eski_satis={
              connection?.accessToken
                ? formatNumber(
                    goldCalculateFormule(
                      serializedSettings["0.5 Gram (22a)_Eski_Satış"],
                      golds?.ALTIN?.satis
                    ) 
                  )
                : formatNumber(
                    goldCalculateFormule(
                      defaultSettings["0.5 Gram (22a)_Eski_Satış"],
                      golds?.ALTIN?.satis
                    ) 
                  )
            }
            yeni_satis={
              connection?.accessToken
                ? formatNumber(
                    goldCalculateFormule(
                      serializedSettings["0.5 Gram (22a)_Yeni_Satış"],
                      golds?.ALTIN?.satis
                    )
                  )
                : formatNumber(
                    goldCalculateFormule(
                      defaultSettings["0.5 Gram (22a)_Yeni_Satış"],
                      golds?.ALTIN?.satis
                    )
                  )
            }
            alis={
              connection?.accessToken
                ? formatNumber(
                    goldCalculateFormule(
                      serializedSettings["0.5 Gram (22a)_Eski_Alış"],
                      golds?.ALTIN?.alis
                    )
                  )
                : formatNumber(
                    goldCalculateFormule(
                      defaultSettings["0.5 Gram (22a)_Eski_Alış"],
                      golds?.ALTIN?.alis
                    )
                  )
            }
          />
          <LongBox
            area="not-qr-code"
            className={golds?.TEK_YENI?.dir}
            fark={farkCalc(golds?.TEK_YENI?.satis / golds?.TEK_YENI?.kapanis)}
            status="success"
            title="1 Gram (22a)"
            eski_satis={
              connection?.accessToken
                ? formatNumber(
                    goldCalculateFormule(
                      serializedSettings["1 Gram (22a)_Eski_Satış"],
                      golds?.ALTIN?.satis
                    )
                  )
                : formatNumber(
                    goldCalculateFormule(
                      defaultSettings["1 Gram (22a)_Eski_Satış"],
                      golds?.ALTIN?.satis
                    )
                  )
            }
            yeni_satis={
              connection?.accessToken
                ? formatNumber(
                    goldCalculateFormule(
                      serializedSettings["1 Gram (22a)_Yeni_Satış"],
                      golds?.ALTIN?.satis
                    )
                  )
                : formatNumber(
                    goldCalculateFormule(
                      defaultSettings["1 Gram (22a)_Yeni_Satış"],
                      golds?.ALTIN?.satis
                    )
                  )
            }
            alis={
              connection?.accessToken
                ? formatNumber(
                    goldCalculateFormule(
                      serializedSettings["1 Gram (22a)_Eski_Alış"],
                      golds?.ALTIN?.alis
                    )
                  )
                : formatNumber(
                    goldCalculateFormule(
                      defaultSettings["1 Gram (22a)_Eski_Alış"],
                      golds?.ALTIN?.alis
                    )
                  )
            }
          />

          <LongBox
            area="not-qr-code"
            className={golds?.CEYREK_YENI?.dir}
            fark={farkCalc(
              golds?.CEYREK_YENI?.satis / golds?.CEYREK_YENI?.kapanis
            )}
            status="fail"
            title="Çeyrek"
            eski_satis={
              connection?.accessToken
                ? formatNumber(
                    goldCalculateFormule(
                      serializedSettings["Çeyrek_Eski_Satış"],
                      golds?.ALTIN?.satis
                    )
                  )
                : formatNumber(
                  goldCalculateFormule(
                    defaultSettings["Çeyrek_Eski_Satış"],
                    golds?.ALTIN?.satis
                  )
                ) //formatNumber(golds?.CEYREK_YENI?.eski_satis)
            }
            yeni_satis={
              connection?.accessToken
                ? formatNumber(
                    goldCalculateFormule(
                      serializedSettings["Çeyrek_Yeni_Satış"],
                      golds?.ALTIN?.satis
                    )
                  )
                : formatNumber(
                  goldCalculateFormule(
                    defaultSettings["Çeyrek_Yeni_Satış"],
                    golds?.ALTIN?.satis
                  )
                ) //formatNumber(golds?.CEYREK_YENI?.satis)
            }
            alis={
              connection?.accessToken
                ? formatNumber(
                    goldCalculateFormule(
                      serializedSettings["Çeyrek_Eski_Alış"],
                      golds?.ALTIN?.alis
                    )
                  )
                : formatNumber(
                  goldCalculateFormule(
                    defaultSettings["Çeyrek_Eski_Alış"],
                    golds?.ALTIN?.alis
                  )
                ) //formatNumber(golds?.CEYREK_YENI?.alis)
            }
          />

          <LongBox
            area="not-qr-code"
            className={golds?.YARIM_YENI?.dir}
            fark={farkCalc(
              golds?.YARIM_YENI?.satis / golds?.YARIM_YENI?.kapanis
            )}
            status="success"
            title="Yarım"
            eski_satis={
              connection?.accessToken
                ? formatNumber(
                    goldCalculateFormule(
                      serializedSettings["Yarım_Eski_Satış"],
                      golds?.ALTIN?.satis
                    )
                  )
                : formatNumber(
                  goldCalculateFormule(
                    defaultSettings["Yarım_Eski_Satış"],
                    golds?.ALTIN?.satis
                  )
                ) //formatNumber(golds?.YARIM_YENI?.eski_satis)
            }
            yeni_satis={
              connection?.accessToken
                ? formatNumber(
                    goldCalculateFormule(
                      serializedSettings["Yarım_Yeni_Satış"],
                      golds?.ALTIN?.satis
                    )
                  )
                : formatNumber(
                  goldCalculateFormule(
                    defaultSettings["Yarım_Yeni_Satış"],
                    golds?.ALTIN?.satis
                  )
                ) //formatNumber(golds?.YARIM_YENI?.satis)
            }
            alis={
              connection?.accessToken
                ? formatNumber(
                    goldCalculateFormule(
                      serializedSettings["Yarım_Eski_Alış"],
                      golds?.ALTIN?.alis
                    )
                  )
                : formatNumber(
                  goldCalculateFormule(
                    defaultSettings["Yarım_Eski_Alış"],
                    golds?.ALTIN?.alis
                  )
                ) //formatNumber(golds?.YARIM_YENI?.alis)
            }
          />

          <LongBox
            area="not-qr-code"
            className={golds?.TEK_YENI?.dir}
            fark={farkCalc(golds?.TEK_YENI?.satis / golds?.TEK_YENI?.kapanis)}
            status="success"
            title="Ata Tek"
            eski_satis={
              connection?.accessToken
                ? formatNumber(
                    goldCalculateFormule(
                      serializedSettings["Ata Tek_Eski_Satış"],
                      golds?.ALTIN?.satis
                    )
                  )
                : formatNumber(
                  goldCalculateFormule(
                    defaultSettings["Ata Tek_Eski_Satış"],
                    golds?.ALTIN?.satis
                  )
                ) //formatNumber(golds?.TEK_YENI?.eski_satis)
            }
            yeni_satis={
              connection?.accessToken
                ? formatNumber(
                    goldCalculateFormule(
                      serializedSettings["Ata Tek_Yeni_Satış"],
                      golds?.ALTIN?.satis
                    )
                  )
                : formatNumber(
                  goldCalculateFormule(
                    defaultSettings["Ata Tek_Yeni_Satış"],
                    golds?.ALTIN?.satis
                  )
                ) //formatNumber(golds?.TEK_YENI?.satis)
            }
            alis={
              connection?.accessToken
                ? formatNumber(
                    goldCalculateFormule(
                      serializedSettings["Ata Tek_Eski_Alış"],
                      golds?.ALTIN?.satis
                    )
                  )
                : formatNumber(
                  goldCalculateFormule(
                    defaultSettings["Ata Tek_Eski_Alış"],
                    golds?.ALTIN?.satis
                  )
                ) //formatNumber(golds?.TEK_YENI?.alis)
            }
          />

          <LongBox
            area="not-qr-code"
            className={golds?.ATA_YENI?.dir}
            fark={farkCalc(golds?.ATA_YENI?.satis / golds?.ATA_YENI?.kapanis)}
            status="fail"
            title="Ata Lira"
            eski_satis={
              connection?.accessToken
                ? formatNumber(
                    goldCalculateFormule(
                      serializedSettings["Ata Lira_Eski_Satış"],
                      golds?.ALTIN?.satis
                    )
                  )
                : formatNumber(
                  goldCalculateFormule(
                    defaultSettings["Ata Lira_Eski_Satış"],
                    golds?.ALTIN?.satis
                  )
                ) //formatNumber(golds?.ATA_YENI?.eski_satis)
            }
            yeni_satis={
              connection?.accessToken
                ? formatNumber(
                    goldCalculateFormule(
                      serializedSettings["Ata Lira_Yeni_Satış"],
                      golds?.ALTIN?.satis
                    )
                  )
                : formatNumber(
                  goldCalculateFormule(
                    defaultSettings["Ata Lira_Yeni_Satış"],
                    golds?.ALTIN?.satis
                  )
                ) //formatNumber(golds?.ATA_YENI?.satis)
            }
            alis={
              connection?.accessToken
                ? formatNumber(
                    goldCalculateFormule(
                      serializedSettings["Ata Lira_Eski_Alış"],
                      golds?.ALTIN?.alis
                    )
                  )
                : formatNumber(
                  goldCalculateFormule(
                    defaultSettings["Ata Lira_Eski_Alış"],
                    golds?.ALTIN?.alis
                  )
                ) //formatNumber(golds?.ATA_YENI?.alis)
            }
          />

          <LongBox
            area="not-qr-code"
            className={golds?.TEK_YENI?.dir}
            fark={farkCalc(golds?.TEK_YENI?.satis / golds?.TEK_YENI?.kapanis)}
            status="success"
            title="Ata 2,5"
            eski_satis={
              connection?.accessToken
                ? formatNumber(
                    goldCalculateFormule(
                      serializedSettings["Ata 2,5_Eski_Satış"],
                      golds?.ALTIN?.satis
                    )
                  )
                : formatNumber(
                  goldCalculateFormule(
                    defaultSettings["Ata 2,5_Eski_Satış"],
                    golds?.ALTIN?.satis
                  )
                ) //formatNumber(golds?.TEK_YENI?.eski_satis * 2.5)
            }
            yeni_satis={
              connection?.accessToken
                ? formatNumber(
                    goldCalculateFormule(
                      serializedSettings["Ata 2,5_Yeni_Satış"],
                      golds?.ALTIN?.satis
                    )
                  )
                : formatNumber(
                  goldCalculateFormule(
                    defaultSettings["Ata 2,5_Yeni_Satış"],
                    golds?.ALTIN?.satis
                  )
                )  //formatNumber(golds?.TEK_YENI?.satis * 2.5)
            }
            alis={
              connection?.accessToken
                ? formatNumber(
                    goldCalculateFormule(
                      serializedSettings["Ata 2,5_Eski_Alış"],
                      golds?.ALTIN?.alis
                    )
                  )
                : formatNumber(
                  goldCalculateFormule(
                    defaultSettings["Ata 2,5_Eski_Alış"],
                    golds?.ALTIN?.alis
                  )
                ) //formatNumber(golds?.TEK_YENI?.alis * 2.5)
            }
          />

          <LongBox
            area="not-qr-code"
            className={golds?.TEK_YENI?.dir}
            fark={farkCalc(golds?.TEK_YENI?.satis / golds?.TEK_YENI?.kapanis)}
            status="success"
            title="Ata 5"
            eski_satis={
              connection?.accessToken
                ? formatNumber(
                    goldCalculateFormule(
                      serializedSettings["Ata 5_Eski_Satış"],
                      golds?.ALTIN?.satis
                    )
                  )
                : formatNumber(
                  goldCalculateFormule(
                    defaultSettings["Ata 5_Eski_Satış"],
                    golds?.ALTIN?.satis
                  )
                ) //formatNumber(golds?.TEK_YENI?.eski_satis * 5)
            }
            yeni_satis={
              connection?.accessToken
                ? formatNumber(
                    goldCalculateFormule(
                      serializedSettings["Ata 5_Yeni_Satış"],
                      golds?.ALTIN?.satis
                    )
                  )
                : formatNumber(
                  goldCalculateFormule(
                    defaultSettings["Ata 5_Yeni_Satış"],
                    golds?.ALTIN?.satis
                  )
                ) //formatNumber(golds?.TEK_YENI?.satis * 5)
            }
            alis={
              connection?.accessToken
                ? formatNumber(
                    goldCalculateFormule(
                      serializedSettings["Ata 5_Eski_Alış"],
                      golds?.ALTIN?.alis
                    )
                  )
                : formatNumber(
                  goldCalculateFormule(
                    defaultSettings["Ata 5_Eski_Alış"],
                    golds?.ALTIN?.alis
                  )
                ) //formatNumber(golds?.TEK_YENI?.alis * 5)
            }
          />
        </Col>

        <Col md={4} className="d-flex flex-column  justify-content-center">
          {/* <LittleCard
            bg_color="blue"
            title="14 Ayar"
            price={
              connection?.accessToken
                ? formatNumber(
                    goldCalculateFormule(
                      serializedSettings["14 Ayar_Gram İşçilik_Satış"],
                      golds?.ALTIN?.satis
                    )
                  )
                : formatNumber(golds?.AYAR14?.satis)
            }
            status={golds?.AYAR14?.dir == "up" ? "success" : "fail"}
          /> */}
          <LittleCard
            className={"list-little-card"}
            bg_color="blue"
            title="Üçlü Burma Bilezik"
            price={
              connection?.accessToken
                ? formatNumber(
                    goldCalculateFormule(
                      serializedSettings["3'lü Burma_İşçiliksiz_Satış"],
                      golds?.ALTIN?.satis
                    )
                  )
                : formatNumber(
                    goldCalculateFormule(
                      defaultSettings["3'lü Burma_İşçiliksiz_Satış"],
                      golds?.ALTIN?.satis
                    )
                  )
            }
            status={golds?.AYAR22?.dir == "up" ? "success" : "fail"}
          />

          <LittleCard
            className={"list-little-card"}
            bg_color="blue"
            title="Desenli Bilezik"
            price={
              connection?.accessToken
                ? formatNumber(
                    goldCalculateFormule(
                      serializedSettings["Desenli_İşçiliksiz_Satış"],
                      golds?.ALTIN?.satis
                    )
                  )
                : formatNumber(
                    goldCalculateFormule(
                      defaultSettings["Desenli_İşçiliksiz_Satış"],
                      golds?.ALTIN?.satis
                    )
                  )
            }
            status={golds?.AYAR22?.dir == "up" ? "success" : "fail"}
          />
          <BigCard
            token={token}
            connection={connection}
            setConnection={setConnection}
            area="qr-code"
          />
        </Col>
      </Row>
    </Container>
  );
};

export default Grid;
