import React, { useEffect, useState } from "react";
import { Col, Container, Form, Row, Spinner, Image } from "react-bootstrap";
import "../assets/css/components/box.css";
import Boximage from "../assets/images/box-image.svg";
import { Cookies } from "react-cookie";

function Box(props) {
  
  const cookies = new Cookies();



  return (
    <Container  fluid className={"box bg-transition "+ (cookies.get("darkMode") == true ? " dark-box " : " light-box ") + props.className }>
      <Row>
  
          <Col xs={9}>
            <h4 className="blue box-h4">{props.title}</h4>
          </Col>
          <Col xs={3} className="d-fex align-items-end justify-content-end text-end">
          <Image src={Boximage}/> 
          </Col>
        
        <Row>
          <Col xs={6} lg={4}>
            <h4 className="blue process-name">Alış</h4>
          </Col>
          <Col xs={6} lg={8} className="d-flex text-end justify-content-end ">
          <h2 className={"blue"}>{props.buy_price}</h2>
          </Col>
        </Row>
        <Row>
          <Col xs={4}>
            <h4 className="blue process-name">Satış</h4>
          </Col>
          <Col xs={8} className="d-flex text-end justify-content-end">
          <h2 className="blue">{props.sell_price}</h2>
          </Col>
        </Row>
       
      </Row>
    </Container>
  );
}

export default Box;
