import { Col, Container, Form, Row, Spinner } from "react-bootstrap";
import "../assets/css/style.css"
import { useEffect, useState } from "react";
import { object, string, number, boolean } from 'yup';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from "axios";
import TableComponent from "../components/Table";
import { io } from "socket.io-client";
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';


function App() {

  const [subCategory, setSubCategory] = useState([])

  const [editSubCategory, setEditSubCategory] = useState([])

  const [loading,setLoading] = useState(false)

  const [has_alis, setHasAlis] = useState(false)
  const [has_satis, setHasSatis] = useState(false)
  const [golds, setGolds] = useState([])

  const [openModal, setOpenModal] = useState(false)
  const [modalContent, setModalContent] = useState({})

  const [socket, setSocket] = useState(null)

  const [getted, setGetted] = useState(false)
  

  const [hasGem, setHasGem] = useState(false)
  const [editHasGem, setEditHasGem] = useState(false)

  const category = [
    {
      category: "İşçilikli Ürün",
      subCategory:[
        {
          name:"Gram İşçilik",
        },
        {
          name:"Adet İşçilik",
        }
      ]
    },
    {
      category: "Ziynet",
      subCategory:[
        {
          name:"Yeni",
        },
        {
          name:"Eski",
        },
      ]
    },
    {
      category: "Bilezik",
      subCategory:[
        {
          name:"İşçiliksiz",
        },
        {
          name:"Gram İşçilikli",
        },
        {
          name:"Adet İşçilikli",
        },
      ]
    }
  ];
  
  axios.defaults.headers= {
      "Accetp":"application/json",
      "Content-Type":"application/json",
      "Authorization": `Bearer ${localStorage.getItem("token")}`
  }

  axios.defaults.baseURL = "https://api.ziynethesapla.com/api"
  

  const submitted = (e) => {
    e.preventDefault();
    setLoading(true)
    const data = new FormData(e.target);
    const json = {};
    data.forEach((value, key) => {
        json[key] = value;
    });

    const schema = object({
      process: string().required("İşlem türü seçiniz"),
      category: string().required(),
      name: string().required("İsim dolu olmalıdır"),
      isAta: boolean().required(),
      gram_has: number("Gram has değeri boş olamaz").min(0.01,"Gram has değeri giriniz").required("Gram has değeri boş olamaz"),
      price_difference: number().required(),
      milyem: number("Milyem değeri boş olamaz").min(0.01,"Milyem değeri giriniz").required("Milyem değeri boş olamaz"),
      profit: number().required(),
      has_gem: boolean().required(),
      gem_difference: hasGem ?  number().min(0.01,"Taş değeri giriniz").required("Taş değeri boş olamaz") : number().notRequired(),
    });

    schema.validate(json).then((value) => {
      axios.post("/golds/create",{
        process: value.process,
        category: value.category,
        subCategory: value.subCategory,
        name: value.name,
      }).then((res) => {
        if (res.data.status === 1) {
          const _id = res.data.data._id
          axios.post("/formules/add",{
            gold: _id,
            isAta: value.isAta,
            gram_has: value.gram_has,
            price_difference: value.price_difference,
            milyem: value.milyem,
            profit: value.profit,
            has_gem: value.has_gem,
            gem_difference: value.gem_difference,
            isCrafted: value.category.includes("İşçilik"),
            isPiece: value.category.includes("Adet"),
            crafted_milyem: 0,
            profit_type: "percentage",
            price_difference_type: "percentage",
          }).then((res) => {
            if (res.data.status === 1) {
              toast.success("Altın başarıyla eklendi")
              // e.target.reset();
              getGolds();
            }
            }).catch((err) => {
              console.log(err.response);
              toast.error(err.message)
            })
        }
        else{
          toast.error("Altın eklenirken bir hata oluştu")
        }
        
      }).catch((err) => {
        toast.error(err.message)
      })
    }
    ).catch((err) => {
      toast.error(err.message)
    })
    setLoading(false)
  }

  const update = (e) => {
    e.preventDefault();
    setLoading(true)
    
    const data = new FormData(e.target);
    const json = {};
    data.forEach((value, key) => {
        json[key] = value;
    });

    const schema = object({
      process: string().required("İşlem türü seçiniz"),
      category: string().required(),
      name: string().required("İsim dolu olmalıdır"),
      isAta: boolean().required(),
      gram_has: number("Gram has değeri boş olamaz").min(0.01,"Gram has değeri giriniz").required("Gram has değeri boş olamaz"),
      price_difference: number().required(),
      milyem: number("Milyem değeri boş olamaz").min(0.01,"Milyem değeri giriniz").required("Milyem değeri boş olamaz"),
      profit: number().required(),
      has_gem: boolean().required(),
      gem_difference: hasGem ?  number().min(0.01,"Taş değeri giriniz").required("Taş değeri boş olamaz") : number().notRequired(),
    });

    schema.validate(json).then((value) => {

      // start
      axios.post("/formules/update",{
        _id: modalContent.formules._id,
        gold: "_id",
        isAta: value.isAta,
        gram_has: value.gram_has,
        price_difference: value.price_difference,
        milyem: value.milyem,
        profit: value.profit,
        has_gem: value.has_gem,
        gem_difference: value.gem_difference,
      }).then((res) => {
        if (res.data.status === 1) {
          toast.success("Altın başarıyla güncellendi")
          // e.target.reset();
          getGolds();
        }
        }).catch((err) => {
          console.log(err.response);
          toast.error(err.message)
        })
        // end

    })
    .catch((err) => {
      toast.error(err.message)
    })
    

  }

  useEffect(()=>{
    if (!socket?.connected) {
      setSocket(io('https://www.haremaltin.com:2083',{"transports":["websocket"]}));
    }
  },[])  

  useEffect(()=>{
    socket?.on("connect", () => {
      console.log("connected to  server");;
    });

    socket?.on('price_changed', function (rec_data) {
        
      var data = rec_data.data;
      // for (var code in data) {
      //     // console.log(data[code]);
      // }

      setHasAlis(data.ALTIN?.alis)
      setHasSatis(data.ALTIN?.satis)

      });

  },[socket])

  useEffect(()=>{
    return ()=>{
        if (socket?.connected) {
          socket?.disconnect();
        }
    }
    },[socket]);

  const onFocus = (e) => e.target.value = ""

  const onBlur = (e) => e.target.value === "" ? e.target.value = 0 : null

  const getGolds = () => {
    axios.post("/golds/list",{
      has_alis: has_alis *1,
      has_satis: has_satis*1,
    }).then((res) => {
      setGetted(true)
      setGolds(res.data.data)
    }).catch((err) => {
      toast.error(err.message)
    })
  }

  useEffect(()=>{
    if (golds.length === 0 && !getted) {
      getGolds();
    }
  },[has_alis,has_satis])

  useEffect(()=>{
    setEditHasGem(modalContent?.has_gem)
  },[modalContent])

  return (
    <Container style={{maxHeight:"100vh"}} fluid>
      <Row>
        <Col md={3}>
          <Form onSubmit={(e)=>submitted(e)}>
          <Form.Group className="mb-3">
              <Form.Label className="input-heading">İşlem</Form.Label>
              <div key={`reverse-radio`} className="d-flex gap-3 mb-3">
                <Form.Check
                  reverse
                  label="Alış"
                  name="process"
                  value={"Alış"}
                  type={"radio"}
                />
                <Form.Check
                  reverse
                  label="Satış"
                  name="process"
                  value={"Satış"}
                  type={"radio"}
                />
              </div>
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label className="input-heading">Kategori</Form.Label>
              <Form.Select name="category" onChange={(e)=>{
                const index = e.target.selectedIndex;
                setSubCategory(category[index].subCategory)
              }}>
                {
                  category.map((item, index) => {
                    return (
                      <option key={index}>{item.category}</option>
                    )
                  })
                }
              </Form.Select>
            </Form.Group>


            {
              subCategory.length > 0 && (
                <Form.Group className="mb-3">
                  <Form.Label className="input-heading">Alt Kategori</Form.Label>
                  <Form.Select name="subCategory">
                    {
                      subCategory.map((item, index) => {
                        return (
                          <option key={index}>{item.name}</option>
                        )
                      })
                    }
                  </Form.Select>
                </Form.Group>
              )
            }
            

            <Form.Group className="mb-3">
              <Form.Label className="input-heading">İsim</Form.Label>
              <Form.Control type="text" name="name" placeholder="Tam" />
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label className="input-heading">Ata Grubu Mu?</Form.Label>
              <div key={`reverse-radio`} className="d-flex gap-3 mb-3">
                <Form.Check
                  reverse
                  label="Evet"
                  name="isAta"
                  value={true}
                  type={"radio"}
                />
                <Form.Check
                  reverse
                  label="Hayır"
                  name="isAta"
                  // set default as selected
                  defaultChecked
                  value={false}
                  type={"radio"}
                />
              </div>
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label className="input-heading">Has Gram</Form.Label>
              <Form.Control type="number" defaultValue={0} onFocus={e=>onFocus(e)} onBlur={e=>onBlur(e)} step={"any"} name="gram_has" placeholder="6.6" />
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label className="input-heading">Has Fiyat Farkı +/-</Form.Label>
              <Form.Control type="number" defaultValue={0} onFocus={e=>onFocus(e)} onBlur={e=>onBlur(e)} step={"any"} name="price_difference" placeholder="12" />
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label className="input-heading">Milyem</Form.Label>
              <Form.Control type="number" defaultValue={0} onFocus={e=>onFocus(e)} onBlur={e=>onBlur(e)} step={"any"} name="milyem" placeholder="1" />
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label className="input-heading">Kar Payı</Form.Label>
              <Form.Control type="number" defaultValue={0} onFocus={e=>onFocus(e)} onBlur={e=>onBlur(e)} step={"any"} name="profit" placeholder="20" />
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label className="input-heading">Taş var mı?</Form.Label>
              <div key={`reverse-radio`} className="d-flex gap-3 mb-3">
                <Form.Check
                  reverse
                  label="Evet"
                  name="has_gem"
                  value={true}
                  type={"radio"}
                  onClick={()=>{
                    setHasGem(true)
                  }}
                />
                <Form.Check
                  reverse
                  label="Hayır"
                  name="has_gem"
                  value={false}
                  type={"radio"}
                  defaultChecked
                  onClick={()=>{
                    setHasGem(false)
                  }}
                />
              </div>
            </Form.Group>

            
            <Form.Group className={`mb-3 ${hasGem ?  "" : "d-none"}`}>
              <Form.Label className="input-heading">Taş Farkı (gram)</Form.Label>
              <Form.Control type="number" defaultValue={0} onFocus={e=>onFocus(e)} onBlur={e=>onBlur(e)} step={"any"} name="gem_difference" placeholder="2" />
            </Form.Group>


            <button type="submit" className="btn btn-primary">
              {loading ? <Spinner animation="border" variant="light" size="sm" /> : "Hesapla"}
            </button>

          </Form>
        </Col>
        <Col md={9}>
          <TableComponent golds={golds} refreshGold={getGolds} setOpenModal={setOpenModal} setModalContent={setModalContent} />
        </Col>
      </Row>

      <Popup open={openModal} onClose={()=>setOpenModal(false)}>
        <Container>
          <Form onSubmit={(e)=>update(e)}>
            <Form.Group className="mb-3">
                <Form.Label className="input-heading">İşlem</Form.Label>
                <div key={`reverse-radio`} className="d-flex gap-3 mb-3">
                  <Form.Check
                    reverse
                    label="Alış"
                    name="process"
                    value={"Alış"}
                    type={"radio"}
                    disabled
                    defaultChecked={modalContent.process === "Alış"}
                  />
                  <Form.Check
                    reverse
                    label="Satış"
                    name="process"
                    value={"Satış"}
                    type={"radio"}
                    disabled
                    defaultChecked={modalContent.process === "Satış"}
                  />
                </div>
              </Form.Group>

              <Form.Group className="mb-3">
                <Form.Label className="input-heading">Kategori</Form.Label>
                <Form.Select disabled name="category" onChange={(e)=>{
                  const index = e.target.selectedIndex;
                  setEditSubCategory(category[index].subCategory)
                }}>
                  {
                    category.map((item, index) => {
                      return (
                        <option key={index}>{item.category}</option>
                      )
                    })
                  }
                </Form.Select>
              </Form.Group>


              {
                subCategory.length > 0 && (
                  <Form.Group className="mb-3">
                    <Form.Label className="input-heading">Alt Kategori</Form.Label>
                    <Form.Select disabled name="subCategory">
                      {
                        editSubCategory.map((item, index) => {
                          return (
                            <option key={index}>{item.name}</option>
                          )
                        })
                      }
                    </Form.Select>
                  </Form.Group>
                )
              }
              

              <Form.Group className="mb-3">
                <Form.Label className="input-heading">İsim</Form.Label>
                <Form.Control disabled type="text" name="name" defaultValue={modalContent.name} placeholder="Tam" />
              </Form.Group>

              <Form.Group className="mb-3">
                <Form.Label className="input-heading">Ata Grubu Mu?</Form.Label>
                <div key={`reverse-radio`} className="d-flex gap-3 mb-3">
                  <Form.Check
                    reverse
                    label="Evet"
                    name="isAta"
                    value={true}
                    type={"radio"}
                    defaultChecked={modalContent.isAta}
                  />
                  <Form.Check
                    reverse
                    label="Hayır"
                    name="isAta"
                    // set default as selected
                    defaultChecked={modalContent.isAta === false}
                    value={false}
                    type={"radio"}
                  />
                </div>
              </Form.Group>

              <Form.Group className="mb-3">
                <Form.Label className="input-heading">Has Gram</Form.Label>
                <Form.Control type="number" defaultValue={modalContent.gram_has} onFocus={e=>onFocus(e)} onBlur={e=>onBlur(e)} step={"any"} name="gram_has" placeholder="6.6" />
              </Form.Group>

              <Form.Group className="mb-3">
                <Form.Label className="input-heading">Has Fiyat Farkı +/-</Form.Label>
                <Form.Control type="number" defaultValue={modalContent.price_difference} onFocus={e=>onFocus(e)} onBlur={e=>onBlur(e)} step={"any"} name="price_difference" placeholder="12" />
              </Form.Group>

              <Form.Group className="mb-3">
                <Form.Label className="input-heading">Milyem</Form.Label>
                <Form.Control type="number" defaultValue={modalContent.milyem} onFocus={e=>onFocus(e)} onBlur={e=>onBlur(e)} step={"any"} name="milyem" placeholder="1" />
              </Form.Group>

              <Form.Group className="mb-3">
                <Form.Label className="input-heading">Kar Payı</Form.Label>
                <Form.Control type="number" defaultValue={modalContent.profit} onFocus={e=>onFocus(e)} onBlur={e=>onBlur(e)} step={"any"} name="profit" placeholder="20" />
              </Form.Group>

              <Form.Group className="mb-3">
                <Form.Label className="input-heading">Taş var mı?</Form.Label>
                <div key={`reverse-radio`} className="d-flex gap-3 mb-3">
                  <Form.Check
                    reverse
                    label="Evet"
                    name="has_gem"
                    value={true}
                    type={"radio"}
                    onClick={()=>{
                      setEditHasGem(true)
                    }}
                    defaultChecked={modalContent.has_gem}
                  />
                  <Form.Check
                    reverse
                    label="Hayır"
                    name="has_gem"
                    value={false}
                    type={"radio"}
                    onClick={()=>{
                      setEditHasGem(false)
                    }}

                    defaultChecked={modalContent.has_gem === false}
                  />
                </div>
              </Form.Group>

              
              <Form.Group className={`mb-3 ${editHasGem ?  "" : "d-none"}`}>
                <Form.Label className="input-heading">Taş Farkı (gram)</Form.Label>
                <Form.Control type="number" defaultValue={modalContent.gem_difference} onFocus={e=>onFocus(e)} onBlur={e=>onBlur(e)} step={"any"} name="gem_difference" placeholder="2" />
              </Form.Group>


              <button type="submit" className="btn btn-primary">
                {loading ? <Spinner animation="border" variant="light" size="sm" /> : "Hesapla"}
              </button>

          </Form>
        </Container>
      </Popup>


      <ToastContainer
        position="bottom-left"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </Container>
  );
}

export default App;
