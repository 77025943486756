import { Col, Container, Image, Row } from "react-bootstrap";
import ziynetlogo from "../assets/images/izmirlinklogo.svg";
import applelink from "../assets/images/applelink.svg";
import googlelink from "../assets/images/googlelink.svg";
import { useNavigate } from "react-router-dom";

const Linkizmir = () => {
    const navigate = useNavigate();
    return (
        <Container className="pt-5 pb-5" fluid style={{backgroundColor:"#0C1528",minHeight:"100vh"}}>
            <Row  className="gap-5 d-flex pt-5 pb-5">
                <Col xs={12} className="text-center">
                    <Image src={ziynetlogo}></Image>
                </Col>
                <Col xs={12} className="d-flex gap-5 flex-column" >
                    <h1 className="text-center text-white">Mobil Uygulaması</h1>
                    <h2 className="text-center" style={{color:"#A1A1A1"}}>Ulaşmak için aşağıdaki butonlara tıklayın.</h2>
                </Col>
                <Col  xs={12} className="d-flex justify-content-center">
                    <div onClick={() => window.open("https://apps.apple.com/ng/app/i-zko/id6455887708")}  className="d-flex justify-content-center gap-5 align-items-center p-3" style={{backgroundColor:"rgba(21, 34, 62, 1)",borderRadius:"15px",cursor:"pointer"}}>
                        <Image src={applelink}></Image>
                        <h1 className="text-center text-white">Apple App Store'da</h1>
                    </div>
                </Col>
                <Col  xs={12} className="d-flex justify-content-center">
                    <div onClick={() => window.open("https://play.google.com/store/apps/details?id=com.code35.izko")}  className="d-flex justify-content-center gap-5 align-items-center p-3" style={{backgroundColor:"rgba(21, 34, 62, 1)",borderRadius:"15px",cursor:"pointer"}}>
                        <Image src={googlelink}></Image>
                        <h1 className="text-center text-white">Google Play Store'da</h1>
                    </div>
                </Col>
                <Col xs={12} className="d-flex justify-content-center">
                    <h1 onClick={() => navigate(-1)} className="text-center" style={{color:"#A1A1A1",cursor:"pointer"}}>Ana sayfaya dönmek için tıklayın.</h1>
                </Col>
            </Row>
        </Container>
    )
}
export default Linkizmir;