import { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import "../assets/css/style.css";
import "react-toastify/dist/ReactToastify.css";
import "reactjs-popup/dist/index.css";
import LittleCard from "./LittleCard";
import BigCard from "./BigCard";
import Cookies from "universal-cookie";
import axios from "axios";
import { io } from "socket.io-client";
import {
  getCookie,
  setCookie,
  formatNumber,
  farkCalc,
  number_format,
} from "./helper";

const Grid = (props) => {

  const {
    socket,
    haremSoket,
    token,
    settings,
    golds,
    hasAlis,
    hasSatis,
    connection,
    serializedSettings,
    defaultSettings,
    setSerializedSettings,
    setSocket,
    setHaremSoket,
    setTime,
    setConnection,
    setSettings,
    setToken,
    setGolds,
    setDefaultSettings,
    goldCalculateFormule
  } = props.useIndex();



  return (
    <Container fluid>
      <Row className="gv-40">
        <Col lg={4} className="d-flex align-items-center">
          <LittleCard bg_color="transparent" title="Ziynet Fiyatları" />
        </Col>
        <Col lg={4} md={6}>
          {/* <LittleCard
            bg_color="blue"
            title="14 Ayar"
            price={formatNumber(golds?.AYAR14?.satis)}
            status={golds?.AYAR14?.dir == "up" ? "success" : "fail"}
            /> */}
          <LittleCard
            bg_color="blue"
            title="Üçlü Burma Bilezik"
            price={
              connection?.accessToken
                ? formatNumber(
                    goldCalculateFormule(
                      serializedSettings["3'lü Burma_İşçiliksiz_Satış"],
                      golds?.ALTIN?.satis
                    ),
                    2
                  )
                : formatNumber(
                    goldCalculateFormule(
                      defaultSettings["3'lü Burma_İşçiliksiz_Satış"],
                      golds?.ALTIN?.satis
                    ),
                    2
                  )
            }
            status={golds?.AYAR22?.dir == "up" ? "success" : "fail"}
          />
        </Col>

        <Col lg={4} md={6}>
          <LittleCard
            bg_color="blue"
            title="Desenli Bilezik"
            price={
              connection?.accessToken
                ? formatNumber(
                    goldCalculateFormule(
                      serializedSettings["Desenli_İşçiliksiz_Satış"],
                      golds?.ALTIN?.satis
                    ),
                    2
                  )
                : formatNumber(
                    goldCalculateFormule(
                      defaultSettings["Desenli_İşçiliksiz_Satış"],
                      golds?.ALTIN?.satis
                    ),
                    2
                  )
            }
            status={golds?.AYAR22?.dir == "up" ? "success" : "fail"}
          />
        </Col>
        <Col lg={4} md={6}>
          <BigCard
            area="not-qr-code"
            className={golds?.TEK_YENI?.dir}
            fark={farkCalc(golds?.TEK_YENI?.satis / golds?.TEK_YENI?.kapanis)}
            status="success"
            title="0.5 Gram (22a)"
            eski_satis={
              connection?.accessToken
                ? formatNumber(
                    goldCalculateFormule(
                      serializedSettings["0.5 Gram (22a)_Eski_Satış"],
                      golds?.ALTIN?.satis
                    )
                  )
                : formatNumber(
                    goldCalculateFormule(
                      defaultSettings["0.5 Gram (22a)_Eski_Satış"],
                      golds?.ALTIN?.satis
                    )
                  )
            }
            yeni_satis={
              connection?.accessToken
                ? formatNumber(
                    goldCalculateFormule(
                      serializedSettings["0.5 Gram (22a)_Yeni_Satış"],
                      golds?.ALTIN?.satis
                    )
                  )
                : formatNumber(
                    goldCalculateFormule(
                      defaultSettings["0.5 Gram (22a)_Yeni_Satış"],
                      golds?.ALTIN?.satis
                    )
                  )
            }
            alis={
              connection?.accessToken
                ? formatNumber(
                    goldCalculateFormule(
                      serializedSettings["0.5 Gram (22a)_Eski_Alış"],
                      golds?.ALTIN?.alis
                    )
                  )
                : formatNumber(
                    goldCalculateFormule(
                      defaultSettings["0.5 Gram (22a)_Eski_Alış"],
                      golds?.ALTIN?.alis
                    )
                  )
            }
          />
        </Col>
        <Col lg={4} md={6}>
          <BigCard
            area="not-qr-code"
            className={golds?.TEK_YENI?.dir}
            fark={farkCalc(golds?.TEK_YENI?.satis / golds?.TEK_YENI?.kapanis)}
            status="success"
            title="1 Gram (22a)"
            eski_satis={
              connection?.accessToken
                ? formatNumber(
                    goldCalculateFormule(
                      serializedSettings["1 Gram (22a)_Eski_Satış"],
                      golds?.ALTIN?.satis
                    )
                  )
                : formatNumber(
                    goldCalculateFormule(
                      defaultSettings["1 Gram (22a)_Eski_Satış"],
                      golds?.ALTIN?.satis
                    )
                  )
            }
            yeni_satis={
              connection?.accessToken
                ? formatNumber(
                    goldCalculateFormule(
                      serializedSettings["1 Gram (22a)_Yeni_Satış"],
                      golds?.ALTIN?.satis
                    )
                  )
                : formatNumber(
                    goldCalculateFormule(
                      defaultSettings["1 Gram (22a)_Yeni_Satış"],
                      golds?.ALTIN?.satis
                    )
                  )
            }
            alis={
              connection?.accessToken
                ? formatNumber(
                    goldCalculateFormule(
                      serializedSettings["1 Gram (22a)_Eski_Alış"],
                      golds?.ALTIN?.alis
                    )
                  )
                : formatNumber(
                    goldCalculateFormule(
                      defaultSettings["1 Gram (22a)_Eski_Alış"],
                      golds?.ALTIN?.alis
                    )
                  )
            }
          />
        </Col>

        <Col lg={4} md={6}>
          <BigCard
            area="not-qr-code"
            className={golds?.CEYREK_YENI?.dir}
            fark={farkCalc(
              golds?.CEYREK_YENI?.satis / golds?.CEYREK_YENI?.kapanis
            )}
            status="fail"
            title="Çeyrek"
            eski_satis={
              connection?.accessToken
                ? formatNumber(
                    goldCalculateFormule(
                      serializedSettings["Çeyrek_Eski_Satış"],
                      golds?.ALTIN?.satis
                    )
                  )
                : formatNumber(
                  goldCalculateFormule(
                    defaultSettings["Çeyrek_Eski_Satış"],
                    golds?.ALTIN?.satis
                  )
                ) //formatNumber(golds?.CEYREK_YENI?.eski_satis)
            }
            yeni_satis={
              connection?.accessToken
                ? formatNumber(
                    goldCalculateFormule(
                      serializedSettings["Çeyrek_Yeni_Satış"],
                      golds?.ALTIN?.satis
                    )
                  )
                : formatNumber(
                  goldCalculateFormule(
                    defaultSettings["Çeyrek_Yeni_Satış"],
                    golds?.ALTIN?.satis
                  )
                ) //formatNumber(golds?.CEYREK_YENI?.satis)
            }
            alis={
              connection?.accessToken
                ? formatNumber(
                    goldCalculateFormule(
                      serializedSettings["Çeyrek_Eski_Alış"],
                      golds?.ALTIN?.alis
                    )
                  )
                : formatNumber(
                  goldCalculateFormule(
                    defaultSettings["Çeyrek_Eski_Alış"],
                    golds?.ALTIN?.alis
                  )
                ) //formatNumber(golds?.CEYREK_YENI?.alis)
            }
          />
        </Col>

        <Col lg={4} md={6}>
          <BigCard
            area="not-qr-code"
            className={golds?.YARIM_YENI?.dir}
            fark={farkCalc(
              golds?.YARIM_YENI?.satis / golds?.YARIM_YENI?.kapanis
            )}
            status="success"
            title="Yarım"
            eski_satis={
              connection?.accessToken
                ? formatNumber(
                    goldCalculateFormule(
                      serializedSettings["Yarım_Eski_Satış"],
                      golds?.ALTIN?.satis
                    )
                  )
                : formatNumber(
                  goldCalculateFormule(
                    defaultSettings["Yarım_Eski_Satış"],
                    golds?.ALTIN?.satis
                  )
                ) //formatNumber(golds?.YARIM_YENI?.eski_satis)
            }
            yeni_satis={
              connection?.accessToken
                ? formatNumber(
                    goldCalculateFormule(
                      serializedSettings["Yarım_Yeni_Satış"],
                      golds?.ALTIN?.satis
                    )
                  )
                : formatNumber(
                  goldCalculateFormule(
                    defaultSettings["Yarım_Yeni_Satış"],
                    golds?.ALTIN?.satis
                  )
                )
            }
            alis={
              connection?.accessToken
                ? formatNumber(
                    goldCalculateFormule(
                      serializedSettings["Yarım_Eski_Alış"],
                      golds?.ALTIN?.alis
                    )
                  )
                : formatNumber(
                    goldCalculateFormule(
                      defaultSettings["Yarım_Eski_Alış"],
                      golds?.ALTIN?.alis
                    )
                  )
            }
          />
        </Col>

        <Col lg={4} md={6}>
          <BigCard
            area="not-qr-code"
            className={golds?.TEK_YENI?.dir}
            fark={farkCalc(golds?.TEK_YENI?.satis / golds?.TEK_YENI?.kapanis)}
            status="success"
            title="Ata Tek"
            eski_satis={
              connection?.accessToken
                ? formatNumber(
                    goldCalculateFormule(
                      serializedSettings["Ata Tek_Eski_Satış"],
                      golds?.ALTIN?.satis
                    )
                  )
                : formatNumber(
                  goldCalculateFormule(
                    defaultSettings["Ata Tek_Eski_Satış"],
                    golds?.ALTIN?.satis
                  )
                ) //formatNumber(golds?.TEK_YENI?.eski_satis)
            }
            yeni_satis={
              connection?.accessToken
                ? formatNumber(
                    goldCalculateFormule(
                      serializedSettings["Ata Tek_Yeni_Satış"],
                      golds?.ALTIN?.satis
                    )
                  )
                : formatNumber(
                  goldCalculateFormule(
                    defaultSettings["Ata Tek_Yeni_Satış"],
                    golds?.ALTIN?.satis
                  )
                ) //formatNumber(golds?.TEK_YENI?.satis)
            }
            alis={
              connection?.accessToken
                ? formatNumber(
                    goldCalculateFormule(
                      serializedSettings["Ata Tek_Eski_Alış"],
                      golds?.ALTIN?.alis
                    )
                  )
                : formatNumber(
                  goldCalculateFormule(
                    defaultSettings["Ata Tek_Eski_Alış"],
                    golds?.ALTIN?.alis
                  )
                ) //formatNumber(golds?.TEK_YENI?.alis)
            }
          />
        </Col>

        <Col lg={4} md={6}>
          <BigCard
            area="not-qr-code"
            className={golds?.ATA_YENI?.dir}
            fark={farkCalc(golds?.ATA_YENI?.satis / golds?.ATA_YENI?.kapanis)}
            status="fail"
            title="Ata Lira"
            eski_satis={
              connection?.accessToken
                ? formatNumber(
                    goldCalculateFormule(
                      serializedSettings["Ata Lira_Eski_Satış"],
                      golds?.ALTIN?.satis
                    )
                  )
                : formatNumber(
                  goldCalculateFormule(
                    defaultSettings["Ata Lira_Eski_Satış"],
                    golds?.ALTIN?.satis
                  )
                ) //formatNumber(golds?.ATA_YENI?.eski_satis)
            }
            yeni_satis={
              connection?.accessToken
                ? formatNumber(
                    goldCalculateFormule(
                      serializedSettings["Ata Lira_Yeni_Satış"],
                      golds?.ALTIN?.satis
                    )
                  )
                : formatNumber(
                  goldCalculateFormule(
                    defaultSettings["Ata Lira_Yeni_Satış"],
                    golds?.ALTIN?.satis
                  )
                ) // formatNumber(golds?.ATA_YENI?.satis)
            }
            alis={
              connection?.accessToken
                ? formatNumber(
                    goldCalculateFormule(
                      serializedSettings["Ata Lira_Eski_Alış"],
                      golds?.ALTIN?.alis
                    )
                  )
                : formatNumber(
                  goldCalculateFormule(
                    defaultSettings["Ata Lira_Eski_Alış"],
                    golds?.ALTIN?.alis
                  )
                ) //formatNumber(golds?.ATA_YENI?.alis)
            }
          />
        </Col>
        <Col lg={4} md={6}>
          <BigCard
            area="not-qr-code"
            className={golds?.TEK_YENI?.dir}
            fark={farkCalc(golds?.TEK_YENI?.satis / golds?.TEK_YENI?.kapanis)}
            status="success"
            title="Ata 2,5"
            eski_satis={
              connection?.accessToken
                ? formatNumber(
                    goldCalculateFormule(
                      serializedSettings["Ata 2,5_Eski_Satış"],
                      golds?.ALTIN?.satis
                    )
                  )
                : formatNumber(
                  goldCalculateFormule(
                    defaultSettings["Ata 2,5_Eski_Satış"],
                    golds?.ALTIN?.satis
                  )
                ) // formatNumber(golds?.TEK_YENI?.eski_satis * 2.5)
            }
            yeni_satis={
              connection?.accessToken
                ? formatNumber(
                    goldCalculateFormule(
                      serializedSettings["Ata 2,5_Yeni_Satış"],
                      golds?.ALTIN?.satis
                    )
                  )
                : formatNumber(
                  goldCalculateFormule(
                    defaultSettings["Ata 2,5_Yeni_Satış"],
                    golds?.ALTIN?.satis
                  )
                )  //formatNumber(golds?.TEK_YENI?.satis * 2.5)
            }
            alis={
              connection?.accessToken
                ? formatNumber(
                    goldCalculateFormule(
                      serializedSettings["Ata 2,5_Eski_Alış"],
                      golds?.ALTIN?.alis
                    )
                  )
                : formatNumber(
                  goldCalculateFormule(
                    defaultSettings["Ata 2,5_Eski_Alış"],
                    golds?.ALTIN?.alis
                  )
                )  //formatNumber(golds?.TEK_YENI?.alis * 2.5)
            }
          />
        </Col>
        <Col lg={4} md={6}>
          <BigCard
            area="not-qr-code"
            className={golds?.TEK_YENI?.dir}
            fark={farkCalc(golds?.TEK_YENI?.satis / golds?.TEK_YENI?.kapanis)}
            status="success"
            title="Ata 5"
            eski_satis={
              connection?.accessToken
                ? formatNumber(
                    goldCalculateFormule(
                      serializedSettings["Ata 5_Eski_Satış"],
                      golds?.ALTIN?.satis
                    )
                  )
                : formatNumber(
                  goldCalculateFormule(
                    defaultSettings["Ata 5_Eski_Satış"],
                    golds?.ALTIN?.satis
                  )
                ) //formatNumber(golds?.TEK_YENI?.eski_satis * 5)
            }
            yeni_satis={
              connection?.accessToken
                ? formatNumber(
                    goldCalculateFormule(
                      serializedSettings["Ata 5_Yeni_Satış"],
                      golds?.ALTIN?.satis
                    )
                  )
                : formatNumber(
                  goldCalculateFormule(
                    defaultSettings["Ata 5_Yeni_Satış"],
                    golds?.ALTIN?.satis
                  )
                ) //formatNumber(golds?.TEK_YENI?.satis * 5)
            }
            alis={
              connection?.accessToken
                ? formatNumber(
                    goldCalculateFormule(
                      serializedSettings["Ata 5_Eski_Alış"],
                      golds?.ALTIN?.alis
                    )
                  )
                : formatNumber(
                  goldCalculateFormule(
                    defaultSettings["Ata 5_Eski_Alış"],
                    golds?.ALTIN?.alis
                  )
                ) //formatNumber(golds?.TEK_YENI?.alis * 5)
            }
          />
        </Col>

        <Col lg={4} md={6}>
          <BigCard
            token={token}
            connection={connection}
            setConnection={setConnection}
            area="qr-code"
          />
        </Col>
      </Row>
    </Container>
  );
};

export default Grid;
