import "../assets/css/style.css";
import { useEffect, useState } from "react";
import "react-toastify/dist/ReactToastify.css";
import "reactjs-popup/dist/index.css";
import "../assets/css/home.css";
import "../assets/css/media.css";
import { io } from "socket.io-client";
import crypto from "crypto-js";
import { getCookie, setCookie } from "../components/helper";
import "swiper/css";
import axios from "axios";
import { Cookies } from "react-cookie";
import { useLocation } from "react-router-dom";



export function useIndex() {
    const cookies = new Cookies();


  // axios.defaults.baseURL = "http://127.0.0.1:4000/api/";
  axios.defaults.baseURL = "https://api.ziynethesapla.com/api/";

  const [socket, setSocket] = useState(null);
  const [haremSoket, setHaremSoket] = useState(null);
  const [token, setToken] = useState("");
  const [settings, setSettings] = useState([]);
  const [serializedSettings, setSerializedSettings] = useState([]);
  const [golds, setGolds] = useState({});
  const [hasAlis, setHasAlis] = useState(0);
  const [hasSatis, setHasSatis] = useState(0);
  const [time, setTime] = useState(new Date());
  const [connection, setConnection] = useState(
    getCookie("connection") ? JSON.parse(getCookie("connection")) : null
  );
  const [defaultSettings, setDefaultSettings] = useState([]);

  const location = useLocation();

  const goldCalculateFormule = (formule, has) => {
    let amount = 1;
    if (formule == undefined) {
      return 1;
    }
    let total_milyem = formule.milyem * 1;
    let total = 0;
    let crafted_milyem = formule.crafted_milyem * 1;
    let profit = formule.profit * amount;

    //Gram İşçilik
    if (formule.isCrafted && !formule.isPiece) {
      total_milyem = amount * (formule.milyem * 1 + crafted_milyem * 1);
    }

    //Adet İşçilik
    if (formule.isPiece) {
      total_milyem = formule.milyem * amount + crafted_milyem * 1;
      profit = formule.profit * 1;
    }
    //Has Settings
    switch (formule.price_difference_type) {
      case "percentage":
        if (formule.price_difference != 0) {
          has = has * 1 + (has * formule.price_difference) / 100;
        }
        break;
      case "price":
        has = has * 1 + formule.price_difference * 1;
        break;
      default:
        break;
    }

    total = formule.gram_has * total_milyem * has;

    //Profit Settings
    switch (formule.profit_type) {
      case "percentage":
        if (formule.profit != 0) {
          total += (total * profit) / 100;
        }
        break;
      case "price":
        total += profit;
        break;

      default:
        break;
    }

    // return total/amount;

    if (formule.isPiece || formule.isCrafted) {
      return total / amount;
    } else {
      return total;
    }
  };


  useEffect(() => {
    if (!socket?.connected) {
      setSocket(
        io("https://socket.ziynethesapla.com/", { transports: ["websocket"] })
      );
      // setSocket(io('http://192.168.1.32:1006/',{"transports":["websocket"]}));
    }
    if (!haremSoket?.connected) {
      setHaremSoket(
        io("wss://socket.haremaltin.com:443", { transports: ["websocket"] })
      );
    }
  }, []);

  useEffect(() => {
    haremSoket?.on("connect", () => {
      // console.log("connected to Harem server");;
    });
  }, [haremSoket]);
  useEffect(() => {
    const intervalId = setInterval(() => {
      setTime(new Date());
    }, 1000);

    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    haremSoket?.off("price_changed");
    haremSoket?.on("price_changed", function (rec_data) {
      var data = rec_data.data;
      let json = {};
      json[`GUMUSTRY`] =
        data?.GUMUSTRY != undefined
          ? {
              alis: data?.GUMUSTRY?.alis,
              satis: data?.GUMUSTRY?.satis,
              dir: data?.GUMUSTRY?.dir.satis_dir,
              kapanis: data?.GUMUSTRY?.kapanis,
            }
          : golds?.GUMUSTRY;
      json[`USDTRY`] =
        data?.USDTRY != undefined
          ? {
              alis: data?.USDTRY?.alis,
              satis: data?.USDTRY?.satis,
              dir: data?.USDTRY?.dir.satis_dir,
              kapanis: data?.USDTRY?.kapanis,
            }
          : golds?.USDTRY;

      json[`EURTRY`] =
        data?.EURTRY != undefined
          ? {
              alis: data?.EURTRY?.alis,
              satis: data?.EURTRY?.satis,
              dir: data?.EURTRY?.dir.satis_dir,
              kapanis: data?.EURTRY?.kapanis,
            }
          : golds?.EURTRY;

      json[`ATA_YENI`] =
        data?.ATA_YENI != undefined
          ? {
              alis: data?.ATA_YENI?.alis,
              satis: data?.ATA_YENI?.satis,
              eski_satis: data?.ATA_ESKI?.satis,
              dir: data?.ATA_YENI?.dir.satis_dir,
              kapanis: data?.ATA_YENI?.kapanis,
            }
          : golds?.ATA_YENI;

      json[`ATA5_YENI`] =
        data?.ATA5_YENI != undefined
          ? {
              alis: data?.ATA5_YENI?.alis,
              satis: data?.ATA5_YENI?.satis,
              eski_satis: data?.ATA5_ESKI?.satis,
              dir: data?.ATA5_YENI?.dir.satis_dir,
              kapanis: data?.ATA5_YENI?.kapanis,
            }
          : golds?.ATA5_YENI;

      json[`TEK_YENI`] =
        data?.TEK_YENI != undefined
          ? {
              alis: data?.TEK_YENI?.alis,
              satis: data?.TEK_YENI?.satis,
              eski_satis: data?.TEK_ESKI?.satis,
              dir: data?.TEK_YENI?.dir.satis_dir,
              kapanis: data?.TEK_YENI?.kapanis,
            }
          : golds?.TEK_YENI;

      json[`YARIM_YENI`] =
        data?.YARIM_YENI != undefined
          ? {
              alis: data?.YARIM_YENI?.alis,
              satis: data?.YARIM_YENI?.satis,
              eski_satis: data?.YARIM_ESKI?.satis,
              dir: data?.YARIM_YENI?.dir.satis_dir,
              kapanis: data?.YARIM_YENI?.kapanis,
            }
          : golds?.YARIM_YENI;

      json[`CEYREK_YENI`] =
        data?.CEYREK_YENI != undefined
          ? {
              alis: data?.CEYREK_YENI?.alis,
              satis: data?.CEYREK_YENI?.satis,
              eski_satis: data?.CEYREK_ESKI?.satis,
              dir: data?.CEYREK_YENI?.dir.satis_dir,
              kapanis: data?.CEYREK_YENI?.kapanis,
            }
          : golds?.CEYREK_YENI;

      json[`ONS`] =
        data?.ONS != undefined
          ? {
              alis: data?.ONS?.alis,
              satis: data?.ONS?.satis,
              dir: data?.ONS?.dir.satis_dir,
              kapanis: data?.ONS?.kapanis,
            }
          : golds?.ONS;

      json[`ALTIN`] =
        data?.ALTIN != undefined
          ? {
              alis: data?.ALTIN?.alis,
              satis: data?.ALTIN?.satis,
              alis_dir: data?.ALTIN?.dir.alis_dir,
              satis_dir: data?.ALTIN?.dir.satis_dir,
              kapanis: data?.ALTIN?.kapanis,
            }
          : golds?.ALTIN;

      json[`AYAR14`] =
        data?.AYAR14 != undefined
          ? {
              alis: data?.AYAR14?.alis,
              satis: data?.AYAR14?.satis,
              dir: data?.AYAR14?.dir.satis_dir,
              kapanis: data?.AYAR14?.kapanis,
            }
          : golds?.AYAR14;

      json[`AYAR22`] =
        data?.AYAR22 != undefined
          ? {
              alis: data?.AYAR22?.alis,
              satis: data?.AYAR22?.satis,
              dir: data?.AYAR22?.dir.satis_dir,
              kapanis: data?.AYAR22?.kapanis,
            }
          : golds?.AYAR22;

      setGolds(json);

      // setHasAlis(data.ALTIN?.alis)
      // setHasSatis(data.ALTIN?.satis)
    });
  }, [haremSoket, golds]);

  useEffect(() => {
    return () => {
      if (haremSoket?.connected) {
        haremSoket?.disconnect();
      }
    };
  }, [haremSoket]);

  useEffect(() => {
    socket?.on("connect", () => {
      // console.log("connected to  server",token);
    });
    socket?.on("message", (data) => {
      // console.log("message",data);
    });
    socket?.on("transfer", (data) => {
      setConnection(data);
      setCookie("connection", JSON.stringify(data));
    });
  }, [socket]);


  useEffect(() => {
    return () => {
      if (socket?.connected) {
        socket?.disconnect();
      }
    };
  }, [socket]);

  useEffect(() => {
    if (token != "") {
      // console.log(token);
      socket?.emit("join", token, (res) => {
        // console.log(res);
      });
    }
  }, [token, socket]);





  useEffect(() => {
    if (connection?.accessToken) {
      axios
        .post(
          "/golds/settings",
          {},
          {
            headers: {
              Authorization: `Bearer ${connection?.accessToken}`,
            },
          }
        )
        .then((res) => {
          setSettings(res.data.data);
        })
        .catch((err) => {
          if (err?.response?.status == 401) {
            if (connection?.refreshToken) {
              axios
                .post("/auth/transfer-refresh", {
                  token: connection?.refreshToken,
                })
                .then((res) => {
                  setConnection(res.data);
                  setCookie("connection", JSON.stringify(res.data));
                })
                .catch((err) => {
                  // console.log(err.response.data);
                  setConnection({});
                  setCookie("connection", null);
                });
            } else {
              setConnection({});
              setCookie("connection", null);
            }
          }
        });
    }
  }, [connection]);

  useEffect(() => {
    let json = {};
    settings.map((item) => {
      const name = item.name;
      item.data.map((sub) => {
        switch (sub.process) {
          case "Alış":
            json[`${name}_${sub.subCategory}_${sub.process}`] = sub;
            break;
          case "Satış":
            json[`${name}_${sub.subCategory}_${sub.process}`] = sub;
            break;

          default:
            json[`${name}_${sub.subCategory}_${sub.process}`] = 0;
            break;
        }
      });
    });
    // console.log(json);
    setSerializedSettings(json);
  }, [hasAlis, hasSatis, settings]);

  useEffect(() => {


    const t = getCookie("token");
    if (t !== "") {
      setToken(t);
    } else {
      const new_token = crypto.MD5(generateRandomToken(8)).toString();
      setCookie("token", new_token);
      setToken(new_token);
    }


    axios
      .post("/golds/no-auth-settings",{
        location:location.pathname
      },{})
      .then((res) => {
        let json = {};
        res?.data?.data.map((item) => {
          const name = item?._id?.name;
          item.data.map((sub) => {
            json[`${name}_${sub.subCategory}_${sub.process}`] = sub;
          });
        });
        setDefaultSettings(json);
      })
      .catch((err) => {
        console.log(err);
      });
      
  }, []);

  function generateRandomToken(length) {
    var characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    var randomToken = "";
    for (var i = 0; i < length; i++) {
      var randomIndex = Math.floor(Math.random() * characters.length);
      randomToken += characters.charAt(randomIndex);
    }
    return randomToken;
  }



  return {
    socket,
    haremSoket,
    token,
    settings,
    golds,
    hasAlis,
    hasSatis,
    connection,
    serializedSettings,
    defaultSettings,
    setSerializedSettings,
    setSocket,
    setHaremSoket,
    setTime,
    setConnection,
    setSettings,
    setToken,
    setGolds,
    setDefaultSettings,
    goldCalculateFormule


  };
}

