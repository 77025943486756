import { Col, Container, Form, Row, Spinner, Image } from "react-bootstrap";
import "../assets/css/components/longBox.css";
import Qrcode from "../assets/images/qr-code.svg";
import { QRCodeSVG } from "qrcode.react";
import { useState } from "react";
import Logout from "../assets/images/logout.svg";
import { setCookie } from "./helper";
import { Cookies } from "react-cookie";

function BigCard(props) {
    const cookies = new Cookies();
  const [user, setUser] = useState(false);
  const { className, fark, eski_satis, alis, yeni_satis } = props;
  const logoutFunction = () => {
    props.setConnection(null);
    setCookie("connection", null);
  };
  return (
    <Container
      fluid
      className={` bg-transition long-box
      ${className}   ${(cookies.get("darkMode") == true ? " dark-long-box " : " light-long-box ")}`}
    >
  
      
            <Row>
              <Col xs={4}>
                <h2 className="dark fw-7">{props.title}</h2>
              </Col>
              <Col xs={2} className="d-flex align-items-center flex-row justify-content-center">
                <h3 className="orange fw-7">{alis}₺</h3>
              </Col>
              <Col xs={2} className="d-flex align-items-center flex-row justify-content-center">
                <h3 className="dark fw-7">{eski_satis}₺</h3>
              </Col>
             
              <Col xs={2} className="d-flex align-items-center  flex-row justify-content-center">
                <h3 className="dark fw-7">{yeni_satis}₺</h3>
              </Col>
              <Col md={2}>
                {props.fark > 0 ? (
                  <div className="d-flex align-items-center justify-content-end gh-20">
                    <span className="arrow-success"></span>
                    <h3 className="fs-26 green">Fark: %{fark}</h3>
                  </div>
                ) : (
                  <div className="d-flex align-items-center gh-20">
                    <span className="arrow-fail"></span>
                    <h3 className="fs-26 red">Fark: %{fark * -1}</h3>
                  </div>
                )}
              </Col>
            </Row>
    </Container>
  );
}

export default BigCard;
