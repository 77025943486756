import axios from 'axios';
import { Pencil, Trash } from 'react-bootstrap-icons';
import Table from 'react-bootstrap/Table';
import { toast } from 'react-toastify';

function BasicExample(props) {
    const {golds,refreshGold,setModalContent,setOpenModal} = props

    const edit = (item) => {
        setOpenModal(true)
        setModalContent(item)
    }

    const delete_ = (id) => {
        axios.post("/formules/delete",{
            "_id":id
        }).then((res)=>{
            console.log(res.data);
            refreshGold()
            toast.success("Silindi")
        }
        ).catch((err)=>{
            toast.error("Silinemedi")
        })
    }

return (
    <Table hover>
    <thead>
        <tr>
        <th>İşlem</th>
        <th>Kategori</th>
        <th>Alt Kategori</th>
        <th>İsim</th>
        <th>Ata Mı?</th>
        <th>Has Gram</th>
        <th>Milyem</th>
        <th>Kâr Payı</th>
        <th>Fiyat Farkı</th>
        <th>Taş</th>
        <th>Taş Farkı</th>
        <th>Fiyat</th>
        <th>Fonksiyonlar</th>
        </tr>
    </thead>
    <tbody>
        
            {
                golds.map((parent_item, index) => {
                    return (
                        parent_item.data.map((item, index) => {
                            return (
                                <tr key={index}>
                                    <td>{parent_item.process}</td>
                                    <td>{parent_item.category}</td>
                                    <td>{parent_item.subCategory}</td>
                                    <td>{item.name}</td>
                                    <td>{item.isAta ? "Evet" : "Hayır"}</td>
                                    <td>{item.gram_has}</td>
                                    <td>{item.milyem}</td>
                                    <td>{item.profit}</td>
                                    <td>{item.price_difference}</td>
                                    <td>{item.has_gem ? "Evet" : "Hayır"}</td>
                                    <td>{item.gem_difference}</td>
                                    <td>{item.price.toFixed(2)}</td>
                                    <td className='d-flex gap-2'>
                                        <button onClick={()=>edit({...item,process:parent_item.process,category:parent_item.category,subCategory:parent_item.subCategory})} className="btn btn-primary btn-sm"><Pencil/></button>
                                        <button onClick={()=>delete_(item._id)} className="btn btn-danger btn-sm"><Trash/></button>
                                    </td>
                                </tr>
                            )
                        })
                    )
                })
            }
    </tbody>
    </Table>
);
}

export default BasicExample;