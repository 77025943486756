import React from 'react';
import ReactDOM from 'react-dom/client';
import Admin from "./Screens/Admin";
import App from './Screens/index.js';
import Izmir from './Screens/indexIzmir.js';
import 'bootstrap/dist/css/bootstrap.min.css';
import { BrowserRouter } from 'react-router-dom';
import { Route, Routes } from "react-router";
import Link from './Screens/Link.js';
import Linkizmir from './Screens/Linkizmir.js';
const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
    <BrowserRouter>
        <Routes>
            <Route path="/" element={<App />} />
            <Route path="/izmir" element={<Izmir />} />
            <Route path="/admin" element={<Admin />} />
            <Route path="/izmir/link" element={<Linkizmir />} />
            <Route path="/link" element={<Link/>} />
        </Routes>
    </BrowserRouter>
);
